import { createMuiTheme, Theme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#42A7C5"
    },
    secondary: {
      main: "#2C3F45"
    },
    neutral: {
      main: "#FDFDFD"
    },
    success: {
      main: "#42A7C5"
    }
  },
  typography: {
    h1: {
      fontFamily: "Bree Serif"
    },
    h2: {
      fontFamily: "Bree Serif"
    },
    h3: {
      fontFamily: "Bree Serif"
    },
    h4: {
      fontFamily: "Bree Serif"
    },
    h5: {
      fontFamily: "Bree Serif"
    },
    h6: {
      fontFamily: "Bree Serif"
    },
    allVariants: {
      fontFamily: "Varela Round"
    }
  }
})

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"]
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"]
  }
}

export default theme
